import React, { useState } from "react"
import * as styles from "./index.module.scss"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"

const items = [
  {
    src: require(`./images/1.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/2.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/3.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/4.jpg`).default,
    altText: "",
    caption: "",
  },
]

const SlideShow = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item, idx) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={idx}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    )
  })

  return (
    <div className={styles.slider_wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-4 col-xl-3">
            <h1>
              <span>Willkommen bei</span>
              Owita Naturals
            </h1>
          </div>
          <div className="col-md-7 col-lg-8 col-xl-9">
            <div className={styles.carousel_wrapper}>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default SlideShow
