import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import SlideShow from "../components/HomePage/SldieShow"
import { farms } from "./our-farms"
import * as styles from "./index.module.scss"
import ProductsList from "../components/OrganicStore/ProductsList"

const HomePage = () => {
  return (
    <>
      <Seo title="Willkommen" />
      <SlideShow />

      <div className="container pt-5">
        <h2 className="mt-5 text-center mb-3">Our Products</h2>
        <div className="row">
          <ProductsList category={""} />
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 offset-md-3 col-xl-4 offset-xl-4 text-center mt-5">
            <h2>Unsere Farmen</h2>
            <p>
              Owita Naturals wird von mehr als 25 engagierten
              landwirtschaftlichen Betrieben unterstützt, die vollständig
              Bio-Produkte anbauen
            </p>
          </div>
        </div>

        <div className="row">
          {farms.map((farm, idx) => (
            <div className={`col-md-6 ${styles.home_farm_block}`} key={idx}>
              <div className="row">
                <div className="col-xl-6">
                  <div className={styles.image}>
                    <img src={farm.image} alt={farm.name} />
                  </div>
                </div>
                <div className="col-xl-6 py-2">
                  <h4>{farm.name}</h4>
                  <p>
                    {farm.location}, Sri Lanka
                    <br />
                    {farm.category}
                  </p>
                  <Link className="btn btn-primary btn-sm" to={"/our-farms"}>
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default HomePage
